import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import WhyUsPage from '../ui/pages/WhyUsPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const WhyUs = props => (
  <>
    <HelmetComponent
      title={metaTags.whyUsTitle}
      description={metaTags.whyUs}
      page="why-us"
    />
    <AppWrapper {...props}>
      <WhyUsPage />
    </AppWrapper>
  </>
)

export default WhyUs
